import * as React from "react"
import { Layout } from "../../components/layout"
import {
  container,
  intro,
  callToAction,
} from "./policies.module.css"

function Policies() {
  return (
    <div className={container}>
      <h1 className={intro}>Broken Window Creations Policies</h1>

      <p className={callToAction}>
        <strong>Custom Created Items</strong>
        <br />
        There are no returns, refunds or exchanges on custom items which includes custom tees and wood cutouts.
        <br />
        <br />
      </p>

      <p className={callToAction}>
        <strong>Returns</strong>
        <br />Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange.
        <br />
        <br />To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.
        <br />
        <br />Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers or magazines cannot be returned. We also do not accept returns on intimates.
        <br />
        <br />To complete your return, we require a receipt or proof of purchase.
        <br />Please do not send your purchase back to the manufacturer.
        <br />
        <br />There are certain situations where only partial refunds are granted (if applicable)
        <br />- Any item not in its original condition, is damaged or missing parts for reasons not due to our error
        <br />- Any item that is returned more than 30 days after delivery
        <br />
        <br />
      </p>

      <p className={callToAction}>
        <strong>Refunds (if applicable)</strong>
        <br />Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
        <br />If you are approved, then your refund will be processed, and a credit will be issued in form of store credit. No refunds will be returned to credit cards.
        <br />
        <br />
        <strong>Clearance Items</strong>
        <br />Only regular priced items may be refunded. Unfortunately, clearance items cannot be refunded.
        <br />
        <br />
        <strong>Exchanges</strong>
        <br />We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email to <a href="sales@bwcreations.store">sales@bwcreations.store</a> and we will send instructions on how to proceed further. We require proof of purchase to execute an exchange.
        <br />
        <br />
        <strong>Gifts</strong>
        <br />If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you. Exchanges or refunds for a gift may also be done in person at the store.
        <br />
        <br />
      </p>
    </div>
  )
}

export default function PoliciesPage({ data }) {
  return (
    <Layout>
      <Policies />
    </Layout>
  )
}
